import React, { Fragment, useState, useEffect } from 'react'
import Layout from '../components/layout';
import Tabs from '../components/tabs/tabs.component';
import '../styles/app.scss';
import ContactUs from '../components/contact-us/contact-us.component';
import GeneralButton from '../components/generalButton/generalButton.component';

export default function StepByStepPage() {

  if (typeof window !== "undefined") {
    if(window.utag){
      window.utag.view({page_subcategory: "step by step guide to hsbc mortgages"})
    }
  }

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleClick = (event) => {
    if (event.target.hasAttribute('data-tealium') || event.target.parentNode.hasAttribute('data-tealium')) {
      if (typeof window !== "undefined") {
        if(window.utag){
          window.utag.link({page_subcategory: "step by step guide to hsbc mortgages"})
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    // Clean up the event listener when the component unmounts

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <Layout
      title='Step-by-step guide to HSBC mortgages'
    >
      <div className="page-section page-section--sbs row">
        <div className='col-xs-12 col-md-12 col-lg-7 col-xl-7'>
          <h1 className='page-title'>Step-by-step guide to <br /> HSBC UK mortgages</h1>
          <p>With our new Mortgage journey, it takes 15 minutes to complete a Decision in Principle, giving customers immediate clarity on how much they can borrow, and evidence of their commitments to vendors and estate agents. It only takes a further 5 minutes to complete the Full Mortgage Application.</p>
          <p>If your customer is a remortgage customer, it can take as little as 48 hours for your offer to be ready for you depending on the outcome of your valuation.</p>
          <div id='steps'></div>
          <p>Throughout the mortgage journey, we will keep you informed of updates via text message and email.</p>

          <h3>Here's a quick overview of the key stages involved:</h3>
        </div>
        <div className='right-side col-xs-12 col-md-12 col-lg-4 col-xl-4 col-lg-offset-1'>
          <ContactUs />
        </div>
        <div className='col-xs-12 col-md-12 custom-tab'>
          <Tabs
            tabs={['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5', 'Step 6']}
            activeTabIndex={selectedTabIndex}
            setActiveTab={setSelectedTabIndex}
            additionalClassName='tabs--horizontal'
            showPagination
            tabContent={[
              [
                <Fragment key={1} >
                  <h2 >Step 1</h2>
                  <h3>Find out how much your customer could borrow?</h3>
                  <p>The first step is to run a pre-application check to find out how much we could lend to your customer. You can use the affordability calculator on our website for an accurate indication of how much we could lend, subject to the accuracy of the information provided.</p>
                  <div className='blue-box'>
                    <p>Use our precise affordability calculator to get an accurate projection of how much we could lend</p>
                    <a
                      href="https://intermediaries.hsbc.co.uk/calculator"
                      className='general-btn general-btn--inline general-btn--white'
                      target='_self'
                      rel='noopener'
                      data-tealium
                    >
                      Calculate now
                    </a>
                  </div>
                </Fragment>
              ],
              [
                <React.Fragment key={2}>
                  <h2>Step 2</h2>
                  <h3>Decision in principle</h3>
                  <p>Our mortgage application journey provides a decision in principle that will give our customers a clear, reliable and immediate indicative lending decision based on the information provided and appropriate credit checks.</p>
                  <p>All the information you enter will be captured and pre-filled when you go on to complete the Full Mortgage application.</p>
                  <div className='blue-box'>
                    <p>Log on to submit a full mortgage application</p>
                    <GeneralButton
                      additionalClass='general-btn general-btn--inline general-btn--white'
                      ariaLabel="Log on to the website"
                      onClick={() => {
                        window.location.href = 'https://platform.intermediaries.hsbc.co.uk/CSSO/LoginRequest?ReturnUrl=/login/';
                      }}
                      isTealiumLink={true}
                    >
                      Apply now
                    </GeneralButton>
                  </div>
                </React.Fragment>
              ],
              [
                <React.Fragment key={3}>
                  <h2>Step 3</h2>
                  <h3>Submit a full mortgage application</h3>
                  <p>When your customer is ready to proceed, log on to submit the Full Mortgage Application. </p>
                  <p>Once the application is submitted, the next step is to upload the relevant supporting documentation in order for the application to progress. To find out more, see <a href='/packaging-requirements'>packaging requirements</a>. You will be informed of updates to your case electronically via email and SMS. To make sure everything goes smoothly, the underwriter may be in touch with you to request more information about the customer, asking you to reply to them directly.</p>
                  <div className='blue-box'>
                    <p>Log on to submit a full mortgage application</p>
                    <GeneralButton
                      additionalClass='general-btn general-btn--inline general-btn--white'
                      ariaLabel="Log on to the website"
                      onClick={() => {
                        window.location.href = 'https://platform.intermediaries.hsbc.co.uk/CSSO/LoginRequest?ReturnUrl=/login/';
                      }}
                      isTealiumLink={true}
                    >
                      Apply now
                    </GeneralButton>
                  </div>
                </React.Fragment>
              ],
              [
                <React.Fragment key={4}>
                  <h2>Step 4</h2>
                  <h3>Post Approval Checks </h3>
                  <p>As soon as the underwriter reaches a decision, we will let you know so you can inform the customer.</p>
                  <p>If the application is approved and all other checks are complete, we advise you that we're preparing the offer documents. Where a booking fee applies and has not yet been paid, we will request the customer to call us and make a payment before we can issue the mortgage offer.</p>
                  <p>While the majority of applications are approved, if your customer's application is declined, we always indicate why.</p>
                </React.Fragment>
              ],
              [
                <React.Fragment key={5}>
                  <h2>Step 5</h2>
                  <h3>Final lending decision and mortgage offer</h3>
                  <p>You will receive an automatic update as soon as a final lending decision has been made by HSBC. You can then contact the customer who will receive their mortgage offer via email.</p>
                  <p>HSBC no longer requires a signed and returned offer document from our customer. A ‘signatureless offer’ will be sent. Where a conveyancer is used, this offer is deemed as accepted, when we receive the certificate of title. For additional borrowing applications, this would be when the offer has been accepted via the Broker portal.</p>
                  <p>We will provide the customer with the mortgage offer securely and electronically. The customer will receive a text message with a password that allows them to open and view their mortgage offer sent directly to their email address. A copy of the offer will be available in the web portal and an electronic copy will be sent to the conveyancer.</p>
                </React.Fragment>
              ],
              [
                <React.Fragment key={6}>
                  <h2>Step 6</h2>
                  <h3>We complete the mortgage</h3>
                  <p>Once the solicitor or licensed conveyancer advises that all the legal requirements have been met, we make the funds available for drawdown.</p>
                  <p>For completion of additional borrowing cases, once the offer has been accepted by the broker, we run a series of checks. Once all checks are fully completed and all charge details are confirmed as registered then we will release the funds (unless the broker has requested they are not released until advised).</p>
                  <p>Please note that solicitors will only liaise with HSBC and the customer directly.</p>
                  <p>We will get in touch to let you know when the mortgage is complete - thank you for placing business with HSBC.</p>
                </React.Fragment>
              ]
            ]}
          />
          <div className="footer-content-side">
            <ContactUs />
          </div>
        </div>
      </div>
    </Layout>
  );
}